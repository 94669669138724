<template>
  <div>
    <v-col
      cols="12"
    >
      <base-material-card
        color="secondary"
        :class="{'card-no-shadow': !page, 'px-5 py-3': page}"
        :header="page"
      >
        <template v-slot:heading>
          <div class="display-2 font-weight-light">
            {{ client ? (client.name + ' - ') : '' }}{{ str['payment'] }}
          </div>
        </template>
        <v-card-text>
          <v-col
            cols="12"
          >
            <div class="mb-2">
              <b>{{ str['id'] }}: </b>
              <span>
                {{ payment.id }}
              </span>
            </div>
            <div class="mb-2">
              <b>{{ str['date'] }}: </b>
              <span>
                {{ payment.date }}
              </span>
            </div>
            <div
              v-if="paymentsViewPermission && type"
              class="mb-2"
            >
              <b>{{ str['type'] }}: </b>
              <span>
                {{ type }}
              </span>
            </div>
            <div
              v-if="paymentsViewPermission && subscriptionLabel && (payment.type !== paymentHotmartType || (!client.paym_info && payment.type === paymentHotmartType)) && payment.type !== paymentVWType && payment.type !== paymentProofType"
              class="mb-2"
            >
              <b>{{ str['subscription'] }}: </b>
              <span>
                {{ subscriptionLabel }}
              </span>
            </div>
            <div
              v-if="paymentsViewPermission && (isHotmart || payment.type === paymentVWType) && payment.cha_id"
              class="mb-2"
            >
              <b>{{ str['transaction'] }}: </b>
              <span>
                {{ payment.cha_id }}
              </span>
              <i
                class="mdi mdi-content-copy"
                style="margin-left: 20px; cursor: pointer;"
                @click="copyTextToClipboard(payment.cha_id)"
              />
            </div>
            <div
              v-if="status"
              class="mb-2"
            >
              <b>{{ str['status'] }}: </b>
              <span :class="status.type + '--text'">
                {{ status.label }}
              </span>
            </div>
            <div
              v-if="paymentsViewPermission && isHotmart && client && client.paym_pri"
              class="mb-2"
            >
              <span>
                {{ client.paym_pri }}
              </span>
            </div>
            <div
              v-if="client && client.paym_info"
              class="mb-2"
            >
              <b>{{ str['plan'] }}: </b>
              <span>
                {{ paymentsViewPermission ? client.paym_info : client.paym_info.split('(')[0].trim() }}
              </span>
            </div>
            <div
              v-if="client && !client.paym_info && client.paym_subt && payment.type !== paymentHotmartType"
              class="mb-2"
            >
              <b>{{ str['plan'] }}: </b>
              <span>
                {{ client.paym_subt }}
              </span>
            </div>
            <div
              v-if="paymentsViewPermission && (payment.value || (editableFields && editableFields.indexOf('value') > -1))"
              class="mb-2"
            >
              <b>{{ str['value'] }}: </b>
              <span>
                {{ payment.value }} {{ currency }}
              </span>
              <v-icon
                v-if="editableFields && editableFields.indexOf('value') > -1"
                style="margin-left: 3px; transform: translateY(-1px);"
                @click="openEditDetails('value', str['value'])"
                color="warning"
                class="cursor-hover"
              >
                mdi-pencil-box
              </v-icon>
            </div>
            <div
              v-if="paymentsViewPermission && (payment.description || (editableFields && editableFields.indexOf('description') > -1))"
              class="mb-2"
            >
              <b>{{ str['payment_method'] }}: </b>
              <span>
                {{ payment.description }}
              </span>
              <v-icon
                v-if="editableFields && editableFields.indexOf('description') > -1"
                style="margin-left: 3px; transform: translateY(-1px);"
                @click="openEditDetails('description', str['payment_method'])"
                color="warning"
                class="cursor-hover"
              >
                mdi-pencil-box
              </v-icon>
            </div>
            <div
              v-if="paymentsViewPermission && (payment.notes || (editableFields && editableFields.indexOf('notes') > -1))"
              class="mb-2"
            >
              <b>{{ str['notes'] }}: </b>
              <span class="label-text-break">
                {{ payment.notes }}
              </span>
              <v-icon
                v-if="editableFields && editableFields.indexOf('notes') > -1"
                style="margin-left: 3px; transform: translateY(-1px);"
                @click="openEditDetails('notes', str['notes'])"
                color="warning"
                class="cursor-hover"
              >
                mdi-pencil-box
              </v-icon>
            </div>
            <div
              v-if="paymentsViewPermission && payment.type === paymentManualType"
              class="mb-2"
            >
              <b>{{ str['payment_changed_date'] }}: </b>
              <span>
                {{ payment.cha_id }}
              </span>
            </div>
            <div
              v-if="paymentsViewPermission && payment.type === paymentManualType && payment.promo_code"
              class="mb-2"
            >
              <b>{{ str['changed_by'] }}: </b>
              <span>
                {{ payment.promo_code }}
              </span>
            </div>
            <div
              v-if="paymentsViewPermission && payment.type !== paymentManualType && payment.promo_code"
              class="mb-2"
            >
              <b>{{ str['promotional_code'] }}: </b>
              <span>
                {{ payment.promo_code }}
              </span>
            </div>
            <div
              v-if="showInvoice && paymentsViewPermission && payment"
              class="mb-2"
            >
              <b>{{ str['invoice'] }}: </b>
              <span v-if="payment.invoice">
                {{ payment.invoice }}
              </span>
              <v-icon
                v-if="payment.invoice && payment.invoice_url"
                style="margin-left: 3px; transform: translateY(-1px);"
                @click="openLink(payment.invoice_url)"
                color="secondary"
                class="cursor-hover"
              >
                mdi-eye
              </v-icon>
              <a
                v-if="!payment.invoice"
                @click="generateInvoice()"
                class="link-text"
                color="secondary"
              >
                {{ str['generate_invoice'] }}
              </a>
            </div>
          </v-col>
          <v-col
            v-if="payment.proof"
            cols="12"
            class="zoom-image-container"
          >
            <div
              style="display: flex; justify-content: flex-end; align-items: center;"
            >
              <v-icon
                color="secondary"
                class="cursor-hover"
                style="font-size: 25px; margin-right: 10px;"
                @click.stop="downloadImage(payment.proof)"
              >
                mdi-tray-arrow-down
              </v-icon>
              <v-icon
                v-if="!zoomImageActive"
                color="blue"
                class="cursor-hover"
                style="font-size: 25px;"
                @click.stop="zoomImage()"
              >
                mdi-magnify-plus-outline
              </v-icon>
              <v-icon
                v-if="zoomImageActive"
                color="blue"
                class="cursor-hover"
                style="font-size: 25px;"
                @click.stop="zoomImage()"
              >
                mdi-magnify-minus-outline
              </v-icon>
            </div>
            <img
              :src="payment.proof"
              alt="Pagamento"
              class="zoom-image mt-2"
              :class="{'zoom-image-active': zoomImageActive}"
            />
          </v-col>
          <v-col
            v-if="paymentsViewPermission && payment.type === paymentManualType"
            cols="12"
          >
            <div style="display: flex; justify-content: center;">
              <div style="position: relative;">
                <v-btn
                  color="blue"
                  class="mr-0"
                >
                  {{ str['upload_proof_payment'] }}
                  
                </v-btn>
                <input
                  id="input-file-image"
                  type="file"
                  @change="addNewProof"
                  accept=".png,.jpg,.jpeg"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: 0;"
                >
              </div>
            </div>
          </v-col>
          <v-col
            v-if="payment.status === 0"
            cols="12"
          >
            <v-btn
              color="error"
              @click="refuse()"
            >
              {{ str['refuse'] }}
            </v-btn>
            <v-btn
              color="success"
              @click="validate()"
              class="ml-4"
            >
              {{ str['validate'] }}
            </v-btn>
            <v-snackbar
              v-model="paymentUpdated"
              :timeout="1500"
              absolute
              top
            >
              {{ str['payment_proof_updated'] }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  {{ str['close'] }}
                </v-btn>
              </template>
            </v-snackbar>
          </v-col>
        </v-card-text>
      </base-material-card>
    </v-col>

    <v-dialog
      v-model="dialogPayday"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['select_payment_date'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-menu
            v-model="datepickerPaydayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="payday"
                :label="str['payment_date']"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="payday"
              style="margin: 0;"
              @change="datepickerPaydayMenu = false"
              :locale="datepickerLanguage"
            />
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="cancelPayday()"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="updatePayday(payday)"
          >
            {{ str['confirm'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogDetail"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['edit_data'] }}
          </span>
        </v-card-title>
        <v-card-text v-if="detailToEdit">
          <v-container>
            <v-row>
              <v-text-field
                v-if="detailToEdit.field.type === 'input'"
                v-model="detailToEdit.value"
                :label="detailToEdit.title"
              />
              <v-textarea
                v-if="detailToEdit.field.type === 'textarea'"
                v-model="detailToEdit.value"
                :label="detailToEdit.title"
                rows="3"
              />
              <v-autocomplete
                v-if="detailToEdit.field.type === 'select'"
                :label="detailToEdit.title"
                item-text="label"
                item-value="value"
                v-model="detailToEdit.value"
                :items="detailToEdit.field.items"
                :no-data-text="str['no_data']"
              />
              <v-menu
                v-if="detailToEdit.field.type === 'datepicker'"
                v-model="datepickerDetailToEditMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="detailToEdit.value"
                    :label="detailToEdit.title"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="detailToEdit.value"
                  style="margin: 0;"
                  @change="datepickerDetailToEditMenu = false"
                  :locale="datepickerLanguage"
                />
              </v-menu>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeEditDetails"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="saveEditDetails"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
  .zoom-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
  }

  .zoom-image-container i {
    font-size: 20px;
    margin-bottom: 10px;
    color: #888;
    cursor: pointer
  }

  .zoom-image {
    max-width: 500px;
  }

  .zoom-image-active {
    zoom: 2;
  }
</style>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    name: 'client-payment',
    props: ['payment', 'page'],
    data () {
      const client = Utils.getStorage('client')
      const user = Utils.getUser()
      let editableFields = []
      if (user && user.configurations && user.configurations.payment && user.configurations.payment.editable_fields && Utils.hasPermission('payments_edit')) {
        editableFields = user.configurations.payment.editable_fields
      }
      let showInvoice = false
      if (user && user.configurations && user.configurations.payment && user.configurations.payment.invoice) {
        showInvoice = true
      }
      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: user,
        client: client ? client : {},
        paymentsViewPermission: Utils.hasPermission('payments_view'),
        editableFields: editableFields,
        showInvoice: showInvoice,
        isHotmart: window.useHotmart ? true : false,
        dialogPayday: false,
        payday: null,
        datepickerPaydayMenu: null,
        paymentUpdated: false,
        status: {},
        type: '',
        zoomImageActive: false,
        paymentManualType: Utils.getPaymentTypeValue('manual'),
        paymentVWType: Utils.getPaymentTypeValue('viva_wallet'),
        paymentHotmartType: Utils.getPaymentTypeValue('hotmart'),
        paymentProofType: Utils.getPaymentTypeValue('proof'),
        imageMaxSize: 600,
        currency: Utils.getCurrency(this.payment),
        detailToEdit: null,
        dialogDetail: false,
        subscriptionLabel: null,
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getStatus()
      this.getType()
      this.getSubscriptionLabel()
    },
    methods: {
      getStatus: function () {
        const self = this
        let status = {
          type: 'default',
          label: window.strings['n_a'],
        }
        const statusItem = Utils.getPaymentStatus().find(function (item) {
          return item.value === self.payment.status
        })
        if (statusItem) {
          status = statusItem
        }
        this.status = status
      },
      getType: function () {
        const self = this
        const types = Utils.getPaymentTypes()
        const type = types.find(function (t) {
          return t.value === self.payment.type
        })
        if (type) {
          this.type = type.label
        } else {
          this.type = window.strings['n_a']
        }
      },
      getSubscriptionLabel () {
        if (this.client && this.client.paym_subt) {
          const paymSubtSplit = this.client.paym_subt.split('#')
          if (this.client.paym_subt.indexOf('day') > -1) {
            if (this.client.paym_subt === '1#day') {
              this.subscriptionLabel = window.strings['subscription_diary']
              return true
            } else {
              this.subscriptionLabel = window.strings['subscription'] + ' ' + window.strings['by'] +  ' ' + paymSubtSplit[0] + ' ' + window.strings['in'] + ' ' + paymSubtSplit[0] + ' ' + window.strings['days']
              return true
            }
          } else if (this.client.paym_subt.indexOf('month') > -1) {
            if (this.client.paym_subt === '1#month') {
              this.subscriptionLabel = window.strings['subscription_monthly']
              return true
            } else {
              this.subscriptionLabel = window.strings['subscription'] + ' ' + window.strings['by'] +  ' ' + paymSubtSplit[0] + ' ' + window.strings['in'] + ' ' + paymSubtSplit[0] + ' ' + window.strings['months']
              return true
            }
          }
          this.subscriptionLabel = this.client.paym_subt
        }
      },
      back: function () {
        this.$router.back()
      },
      validate: function () {
        this.updatePayment(1)
      },
      refuse: function () {
        const self = this

        this.$confirm(
          window.strings['want_refuse_payment'],
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.updatePayment(2)
        }).catch(() => { })
      },
      updatePayment: function (status) {
        const self = this
        Api.updatePayment({
          db_id: this.payment.db_id,
          id: this.payment.id,
          data: {
            status: status
          }
        }, function (response) {
          if (response.success) {
            if (status === 1 && self.payment.type !== self.paymentManualType) {
              self.paymentUpdated = true
              self.openPayday()
            } else {
              self.back()
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openPayday: function () {
        this.dialogPayday = true
        if (this.client.payday === window.strings['n_a']) {
          this.payday = ''
        } else {
          this.payday = this.client.payday
        }
      },
      cancelPayday: function () {
        this.dialogPayday = false
        this.back()
      },
      updatePayday: function (payday) {
        const self = this
        this.dialogPayday = false
        Api.updateUser({
          id: this.client.id,
          payday: payday,
        }, function (response) {
          if (response.success) {
            self.client.payday = response.data.payday
            Utils.setStorage('client', self.client)
            self.back()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      zoomImage: function () {
        this.zoomImageActive = !this.zoomImageActive
      },
      addNewProof (event) {
        const self = this
        const file = event.srcElement.files[0]
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = function (e) {
          const image = new Image()
          image.src = reader.result

          image.onload = function () {
            if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
              self.updatePaymentProof(e.target.result)
            } else {
              self.updatePaymentProof(resizeImage(image))
            }
            document.getElementById('input-file-image').value = ''
          }
        }
        reader.onerror = function () {}

        function resizeImage (image) {
          const canvas = document.createElement('canvas')
          let width = image.width
          let height = image.height

          if (width > height) {
            if (width > self.imageMaxSize) {
              height *= self.imageMaxSize / width
              width = self.imageMaxSize
            }
          } else {
            if (height > self.imageMaxSize) {
              width *= self.imageMaxSize / height
              height = self.imageMaxSize
            }
          }
          canvas.width = width
          canvas.height = height
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, width, height)
          return canvas.toDataURL()
        }
      },
      updatePaymentProof: function (proof) {
        const self = this
        Api.updatePayment({
          db_id: this.payment.db_id,
          id: this.payment.id,
          data: {
            proof: proof
          }
        }, function (response) {
          if (response.success) {
            self.payment.proof = proof
            Utils.setStorage('payment', self.payment)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      downloadImage: function (image) {
        const a = document.createElement('a')
        a.href = image
        a.download = 'image.png'
        a.click()
      },
      openEditDetails(id, title) {
        let field = {
          type: 'input'
        }
        if (id === 'description') {
          field = {
            type: 'select',
            items: Utils.getPaymentMethods()
          }
        }
        if (id === 'notes') {
          field = {
            type: 'textarea'
          }
        }
        this.detailToEdit = {
          id: id,
          title: title,
          value: this.payment[id],
          field: field
        }
        this.dialogDetail = true
      },
      closeEditDetails() {
        this.dialogDetail = false
        this.detailToEdit = null
      },
      saveEditDetails() {
        const self = this
        if (!this.detailToEdit) {
          return false
        }
        const data = {}
        data[this.detailToEdit.id] = this.detailToEdit.id === 'value' ? parseFloat(this.detailToEdit.value) : this.detailToEdit.value
        this.$isLoading(true)
        Api.updatePayment({
          db_id: this.payment.db_id,
          id: this.payment.id,
          data: data
        }, function (response) {
          if (response.success) {
            self.refreshPayment()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      refreshPayment: function () {
        const self = this
        Api.getPayment({
          db_id: this.client.dbId,
          id: this.payment.id
        }, function (response) {
          self.$isLoading(false)
          const paymentData = response.data[0]
          if (response.success && paymentData) {
            if (self.detailToEdit) {
              self.payment[self.detailToEdit.id] = paymentData[self.detailToEdit.id]
            } else {
              self.payment = paymentData
            }
            Utils.setStorage('payment', self.payment)
            self.closeEditDetails()
          } else {
            self.$alert(
              response.message ? response.message : window.strings['payment_not_found'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      copyTextToClipboard: function (text) {
        navigator.clipboard.writeText(text)
      },
      generateInvoice: function () {
        const self = this
        this.$isLoading(true)
        Api.generateClientPaymentInvoice({
          db_id: this.client.dbId,
          id: this.payment.id
        }, function (response) {
          if (response.success) {
            self.refreshPayment()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openLink: function (url) {
        window.open(url, '_blank').focus()
      },
    },
  }
</script>
